import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.dev';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {BaseListResultModel} from '../models/base-list-result.model';
import {catchError, map} from 'rxjs/operators';
import {BaseResultModel} from '../models/base-result.model';
import {ApproverModel, CreateApproverModel} from '../models/approver.model';

@Injectable({
	providedIn: 'root',
})
export class ApproversService {

	private readonly apiUrl = environment.API_URL;

	constructor(private http: HttpClient) {
	}

	getAllApprovers(queryFilter: any): Observable<BaseListResultModel<ApproverModel>> {
		return this.http.post<any>(`${this.apiUrl}/approver/query`, queryFilter)
			.pipe(
				map((res: any) => {
					return res.result as BaseListResultModel<ApproverModel>;
				}),
				catchError(this.handleError),
			)
	}

	createApprover(approver: CreateApproverModel): Observable<ApproverModel> {
		return this.http.post<any>(`${this.apiUrl}/approver`, approver)
			.pipe(catchError(this.handleError));
	}

	getApproverByLogin(login: string): Observable<ApproverModel> {
		return this.http.get<any>(`${this.apiUrl}/approver?keys=${login}`)
			.pipe(catchError(this.handleError));
	}

	getApproverById(id: number): Observable<BaseResultModel<ApproverModel>> {
		return this.http.get<any>(`${this.apiUrl}/approver?keys=${id}`)
			.pipe(catchError(this.handleError));
	}

	updateApprover(approver: CreateApproverModel): Observable<ApproverModel> {
		return this.http.put<any>(`${this.apiUrl}/approver`, approver)
			.pipe(catchError(this.handleError));
	}

	deleteApprover(approver: CreateApproverModel): Observable<any> {
		return this.http.delete<any>(`${this.apiUrl}/approver/delete`, {body: approver})
			.pipe(catchError(this.handleError));
	}

	private handleError(error: HttpErrorResponse): Observable<never> {
		let errorMessage = 'An unknown error occurred!';
		if (error.error instanceof ErrorEvent) {
			// Client-side or network error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// Backend error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		return throwError(errorMessage);
	}
}
