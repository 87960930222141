import {Component} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {AppTranslationService} from './common/i18n/app-translation.service';
import {CodebooksService} from './application/services/codebooks.service';
import {AppThemeService} from './application/theme/app-theme.service';
import {AuthenticationService} from './common/auth/authentication.service';
import {first} from 'rxjs';
import {TicketService} from './application/services/ticket.service';
import {UserService} from './application/services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [`

        #loading {
            position: fixed;
            z-index: 999999;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.27);
            display: none;
            justify-content: center;
            align-items: center;
        }

    `]
})

export class AppComponent {

    menuMode = 'static';

    constructor(
        private primengConfig: PrimeNGConfig,
        private appTranslationService: AppTranslationService,
        private codebooksService: CodebooksService,
        private appThemeService: AppThemeService,
        private authService: AuthenticationService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';
        this.loadApp();
    }

    async loadApp() {
        const res = await this.appTranslationService.initApp();

        setTimeout(() => {
            this.authService.getCurrentUser().pipe(first()).subscribe({
                next: (user) => {
                    AuthenticationService.updateUserData(user);
                }
            });

            this.userService.checkUserIsCarServiceManager();
        }, 700);

        this.appThemeService.initTheme();

        await this.codebooksService.preloadCodebooks();

        this.appTranslationService.changedLanguage.subscribe({
            next: () => {
                this.initPrimengTranslation();
            }
        });
    }

    initPrimengTranslation() {
        this.appTranslationService.getTranslation('CALENDAR').subscribe(translations => {
            this.primengConfig.setTranslation({
                dayNames: translations.DAY_NAMES,
                dayNamesShort: translations.DAY_NAMES_SHORT,
                dayNamesMin: translations.DAY_NAMES_MIN,
                monthNames: translations.MONTH_NAMES,
                monthNamesShort: translations.MONTH_NAMES_SHORT,
                today: translations.TODAY,
                clear: translations.CLEAR,
                weekHeader: translations.WEEK_HEADER,
                firstDayOfWeek: 1 // Optional: Set Monday as the first day of the week
            });
        });
    }
}
