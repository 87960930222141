import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule as MyCommonModule} from './common/common.module';
import {HttpLoaderFactory, I18nModule} from './common/i18n/i18n.module';
import {AUTH_INTERCEPTOR_PROVIDER} from './common/auth/interceptors/auth-interceptor.provider';
import {AuthGuard} from './common/auth/guards/auth.guard';
import {AuthenticationService} from './common/auth/authentication.service';
import {RoleGuard} from './common/auth/guards/role.guard';
import {CookieService} from 'ngx-cookie-service';
import {AppRedirectService} from './common/auth/app-redirect.service';
import {
    MissingTranslationHandler,
    TranslateLoader,
    TranslateModule,
    TranslateParser,
    TranslatePipe,
} from '@ngx-translate/core';
import {PercentageSignTranslateParser} from "./common/i18n/percentage-sign-translate-parser";
import {AppMissingTranslationService} from "./common/i18n/app-missing-translation.service";
import {MessagesModule} from 'primeng/messages';
import {ToastModule} from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppThemeModule} from './application/theme/app-theme.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AppTranslationService} from './common/i18n/app-translation.service';
import {TicketService} from './application/services/ticket.service';
import {UserService} from './application/services/user.service';
import {UserRolePipe} from './common/pipes/user-role.pipe';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MyCommonModule,
		I18nModule,
		TranslateModule.forRoot(
			{
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
				parser: {
					provide: TranslateParser,
					useClass: PercentageSignTranslateParser,
				},
				missingTranslationHandler: {
					provide: MissingTranslationHandler,
					useExisting: AppMissingTranslationService,
				},
			},
		),
		MessagesModule,
		ToastModule,
		ProgressSpinnerModule,
		AppThemeModule,
		ConfirmDialogModule,
	],
    declarations: [
        AppComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        AUTH_INTERCEPTOR_PROVIDER,
        AuthGuard,
        RoleGuard,
        AuthenticationService,
        CookieService,
        AppRedirectService,
        MessageService,
        TranslatePipe,
		ConfirmationService,
		AppTranslationService,
		UserService,
		UserRolePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
