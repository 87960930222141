import {Injectable} from '@angular/core';
import {environment, UserRolesEnum} from '../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, first, firstValueFrom, Observable} from 'rxjs';
import {BaseResultModel} from '../models/base-result.model';
import {User} from '../models/user.model';
import {UserRolePipe} from '../../common/pipes/user-role.pipe';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../common/auth/authentication.service';
import {ApproversService} from './approvers.service';

@Injectable()
export class UserService {

	private readonly apiUrl = environment.API_URL;

	private approverData: BaseResultModel<any> | null = null;
	
	canCreateAsCarServiceManager: BehaviorSubject<boolean> = new BehaviorSubject(false);

	canApprove: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		private http: HttpClient, private userRole: UserRolePipe,
		private approversService: ApproversService
	) {

	}

	checkUserIsCarServiceManager() {
		const user = AuthenticationService.getLoggedUser();

		if (user && user.login) {
			this.approversService
				.getApproverByLogin(user.login)
				.pipe(first())
				.subscribe({
					next: (response) => {

						if (this.userRole.transform([UserRolesEnum.CarAdmin])) {
							this.canCreateAsCarServiceManager.next(true);
						} else {
							this.canCreateAsCarServiceManager.next(false);
						}

						this.canApprove.next(true);

					},
					error: (error) => {
						this.canCreateAsCarServiceManager.next(false);

						this.canApprove.next(false);
					},
				});
		}
	}

	getUserById(id: number): Observable<BaseResultModel<User>> {
		return this.http.get<any>(`${this.apiUrl}/user?keys=${id}`);
	}

	searchUser(phrase: string): Observable<User[]> {
		return this.http.get<any>(`${this.apiUrl}/user/searchCollateUvt/${phrase}`);
	}

	isCarServiceManager(): boolean {
		return this.userRole.transform(environment.userRoles.ExtendedApprovers);
	}

	canEditAsCarServiceManager(): boolean {
		return this.userRole.transform(environment.userRoles.ExtendedApprovers);
	}

	// Fetch and cache the approver data
	async preloadUserApproverType(): Promise<void> {
		try {
			this.approverData = await firstValueFrom(
				this.http.get<BaseResultModel<any>>(`${this.apiUrl}/ticket/is-approver`)
					.pipe(
						map((res) => {
								//this.saveUserWithRoles(res.result);
								return res.result
							},
						)),
			);
		} catch (error) {
			console.error('Error preloading approver data:', error);
			throw error;
		}
	}

	saveUserWithRoles(roles: number[]) {
		let user = AuthenticationService.getStoredAuthData();

		let role = '';

		if (roles.includes(0)) {
			role = UserRolesEnum.CarDep;
		}

		if (roles.includes(2)) {
			role = UserRolesEnum.OhseAdmin;
		}

		if (roles.includes(3)) {
			role = UserRolesEnum.CarRtu;
		}

		if (roles.includes(1)) {
			role = UserRolesEnum.CarAdmin;
		}

		if (user && user.user && user.user.roles) {
			user.user.roles = Array.from(new Set([...user.user.roles, role]));
			user.user.rolesAsString = user.user.roles.join(',');

			console.log(user)

			setTimeout(() => {
				AuthenticationService.updateAuthData(user);
			}, 50);
		}
	}

}
